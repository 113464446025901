import { render, staticRenderFns } from "./TestDriveBookings.vue?vue&type=template&id=639873a1&scoped=true"
import script from "./TestDriveBookings.vue?vue&type=script&lang=js"
export * from "./TestDriveBookings.vue?vue&type=script&lang=js"
import style0 from "./TestDriveBookings.vue?vue&type=style&index=0&id=639873a1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "639873a1",
  null
  
)

export default component.exports