<template>
  <div class='container'>
  <h2>Add A TestDriveBookings</h2>
   <button @click='save()' type='button' class='btn btn-success'>Save</button>
  <button @click='toggleView()' type='button' class='btn btn-warning'>Cancel</button>
	<div class='row'>
            <div class=' col-md-4'><label class='' for='Fullname'>Fullname</label><input class=' form-control'   
                id='Fullname' name='Fullname' v-model='newTestDriveBookings.Fullname' placeholder='Fullname' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='EmailAddress'>EmailAddress</label><input class=' form-control'   
                id='EmailAddress' name='EmailAddress' v-model='newTestDriveBookings.EmailAddress' placeholder='EmailAddress' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='Cellphone'>Cellphone</label><input class=' form-control'   
                id='Cellphone' name='Cellphone' v-model='newTestDriveBookings.Cellphone' placeholder='Cellphone' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='PreferredContactMethod'>PreferredContactMethod</label><input class=' form-control'   
                id='PreferredContactMethod' name='PreferredContactMethod' v-model='newTestDriveBookings.PreferredContactMethod' placeholder='PreferredContactMethod' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='VehicleModel'>VehicleModel</label><input class=' form-control'   
                id='VehicleModel' name='VehicleModel' v-model='newTestDriveBookings.VehicleModel' placeholder='VehicleModel' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='EnquiryDate'>EnquiryDate</label><input class=' form-control'   
                id='EnquiryDate' name='EnquiryDate' v-model='newTestDriveBookings.EnquiryDate' placeholder='EnquiryDate' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='TestDriveDate'>TestDriveDate</label><input class=' form-control'   
                id='TestDriveDate' name='TestDriveDate' v-model='newTestDriveBookings.TestDriveDate' placeholder='TestDriveDate' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='Dealarship'>Dealarship</label><input class=' form-control'   
                id='Dealarship' name='Dealarship' v-model='newTestDriveBookings.Dealarship' placeholder='Dealarship' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='LastUpdated'>LastUpdated</label><input class=' form-control'   
                id='LastUpdated' name='LastUpdated' v-model='newTestDriveBookings.LastUpdated' placeholder='LastUpdated' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
    <br/>
            </div>
    <button @click='save()' type='button' class='btn btn-success'>Save</button>
    <button @click='toggleView()' type='button' class='btn btn-warning'>Cancel</button>
    <br/>
    <br/>
    </div>
    
</template>
<script>
import _ from 'lodash'; 
import FileUploader from '@/components/custom/FileUploader.vue'; 
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  name: 'addTestDriveBookings',
  created: function() {
  },
  data() {
      return {
          newTestDriveBookings: {},
        uploadedImageData: {}, 
        uploadedImageUrl: null, 
        editingImage: false 
      }
  },
  components: { 
    FileUploader 
  }, 
  methods: {
    toggleView: function() {
      this.$emit('closeAddTestDriveBookings')
    },
	updateImageData: function(value) { 
      this.uploadedImageData = value; 
    }, 
	updateImageUrl: function(value) {  
      this.uploadedImageUrl = value; 
    }, 
    editImage: function() { 
      this.editingImage = !this.editingImage; 
    }, 
    updateImage: function() { 
      this.editingImage = !this.editingImage; 
    }, 
    getImageSource(data) {
      if(this.uploadedImageUrl)
      {
        return this.uploadedImageUrl;
      }
        if (!this.isNull(data) && !this.isNull(data.Image)) {
        if (data.Image) {
            return this.$store.state.cdnUrl + data.Image;
        }
        else {
            return data.Image;
        }
      }
      return '';
    },
    isNull: function(obj) {
        if (typeof obj === 'undefined' || obj === null || obj === 'null') {
            return true;
        }
    return false;
    },
    save: function() {
    var payload = {
          data: this.newTestDriveBookings,
          image: this.uploadedImageData,
          success: response => {
            this.$emit('addTestDriveBookingsSuccess')
          },
          error: error => { miniToastr['error'](error, 'Error', 1000, null)}
        }
        this.$store.dispatch('addTestDriveBookings', payload);
    }
  }
}
</script>
<style scoped> 
.previewImage { 
  max-height: 100px; 
} 
</style> 
