<template>
  <div class='card'>
    <div class='container'>
      <b-modal ref='addTestDriveBookingsModal' hide-footer>
        <addTestDriveBookings @closeAddTestDriveBookings='toggleAddTestDriveBookings()' @addTestDriveBookingsSuccess='addTestDriveBookingsSuccess()'></addTestDriveBookings>
      </b-modal>
      <b-modal ref='editTestDriveBookingsModal' hide-footer>
        <editTestDriveBookings
          :editingTestDriveBookings='currentTestDriveBookings'
          @closeEditTestDriveBookings='toggleEditTestDriveBookings()'
          @editTestDriveBookingsSuccess='editTestDriveBookingsSuccess()'
        ></editTestDriveBookings>
      </b-modal>
      <div class='row'>
        <h2>TestDriveBookings</h2>
      </div>
      <div class='row'>
        <button @click='toggleAddTestDriveBookings()' type='button' class='btn btn-success'>Add</button>
      </div>
      <div class='row'>
        <br>
        <datatable :dataItems='items' @rowClicked='editItem' :dataColumns='columns' :searching='true' :sorting='true' @editRow='editItem' :order='[1, "dec"]' class='text-center' striped bordered />
      </div>
    </div>
  </div>
</template>
<script>
import addTestDriveBookings from '../../components/addTestDriveBookings';
import editTestDriveBookings from '../../components/editTestDriveBookings';
import VueNotifications from 'vue-notifications';
import {    Datatable  } from '../../mdb/components/Datatable';
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      columns: [
              {
                label: 'Id',
                field: 'Id',
                sort: 'asc',
              },
              {
                label: 'Fullname',
                field: 'Fullname',
                sort: 'asc',
              },
              {
                label: 'EmailAddress',
                field: 'EmailAddress',
                sort: 'asc',
              },
              {
                label: 'Cellphone',
                field: 'Cellphone',
                sort: 'asc',
              },
              {
                label: 'PreferredContactMethod',
                field: 'PreferredContactMethod',
                sort: 'asc',
              },
              {
                label: 'VehicleModel',
                field: 'VehicleModel',
                sort: 'asc',
              },
              {
                label: 'EnquiryDate',
                field: 'EnquiryDate',
                sort: 'asc',
              },
              {
                label: 'TestDriveDate',
                field: 'TestDriveDate',
                sort: 'asc',
              },
              {
                label: 'Dealarship',
                field: 'Dealarship',
                sort: 'asc',
              },
              {
                label: 'LastUpdated',
                field: 'LastUpdated',
                sort: 'asc',
              },
      ],
      items: [],
      addTestDriveBookingsOpen: false,
      editTestDriveBookingsOpen: false,
      currentTestDriveBookings: {}
    };
  },
  components: {
    addTestDriveBookings,
    editTestDriveBookings,
    Datatable,
  },
  created: function() {
    this.getTestDriveBookings();
    this.addTestDriveBookingsOpen = false;
    this.editTestDriveBookingsOpen = false;
  },
  methods: {
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return '';
    },
    isNull: function(obj) {
      if (typeof obj === 'undefined' || obj === null || obj === 'null') {
        return true;
      }
      return false;
    },
 getTestDriveBookings() {
      var payload = {
        success: response => {
          this.items = response.data;
        },
        error: error => { miniToastr['error'](error, 'Error', 1000, null)}
      }
      this.$store.dispatch('getTestDriveBookings', payload);
    },
    editItem(TestDriveBookings) {
      this.toggleEditTestDriveBookings();
      this.currentTestDriveBookings = TestDriveBookings;
    },
    toggleAddTestDriveBookings() {
      if(this.addTestDriveBookingsOpen)
      {
        this.$refs.addTestDriveBookingsModal.hide()
      }
      else{
        this.$refs.addTestDriveBookingsModal.show()
      }
      this.addTestDriveBookingsOpen = !this.addTestDriveBookingsOpen;
    },
    addTestDriveBookingsSuccess() {
      this.toggleAddTestDriveBookings();
      miniToastr['success']('TestDriveBookings Added', 'Success', 1000, null);
      this.getTestDriveBookings();
    },
    toggleEditTestDriveBookings() {
      if(this.editTestDriveBookingsOpen)
      {
        this.$refs.editTestDriveBookingsModal.hide()
      }
      else{
        this.$refs.editTestDriveBookingsModal.show()
      }
      this.editTestDriveBookingsOpen = !this.editTestDriveBookingsOpen;
    },
    editTestDriveBookingsSuccess() {
      this.toggleEditTestDriveBookings();
      miniToastr['success']('TestDriveBookings Edited', 'Success', 1000, null);
      this.getTestDriveBookings();
    }
  }
};
</script>
<style scoped>
.TestDriveBookingsThumbnail {
  height: 50px;
}
</style>
